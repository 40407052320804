<template>
  <v-autocomplete
    v-model="pickerValue"
    :disabled="loading || cities.length < 1 || !editable"
    :loading="loading"
    :items="cities"
    item-value="uuid"
    item-text="name"
    v-bind="$attrs"
    hide-details
    hide-selected
    solo
    flat
    hide-no-data
    clearable
    :@click-clear="clearFilter()"
    height="56px"
    color="#FFFFFF"
    dark
    :class="validateUUIDInList ? 'with-filter' : 'without-filter'"
    :background-color="validateUUIDInList ? '#EE8146' : '#FFF0E8'"
  >
    <template v-slot:label>
      <p
        style="color: #EE8146"
        class="text-sm-center ml-3"
      >
        Ciudad
      </p>
    </template>
    <template v-slot:append>
      <v-icon
        color="#EE8146"
        small
      >
        far fa-chevron-down
      </v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CityPickerV3',
  props: {
    region: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      cities: [],
      loading: false,
    };
  },
  computed: {
    validateUUIDInList() {
      const uuidList = this.cities.map(data => data.uuid);
      return uuidList.includes(this.value);
    },
  },
  watch: {
    pickerValue() {
      if (this.pickerValue) {
        this.$emit('input', this.pickerValue);
      }
    },
    value(newVal) {
      this.pickerValue = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadCities();
    });
  },
  methods: {
    clearFilter() {
      if (this.pickerValue === undefined) {
        this.$emit('input', this.pickerValue);
      }
    },
    shortensSelectedText(value) {
      if (value.length > 10) {
        return `${value.substr(0, 10)}...`;
      }
      return value;
    },
    loadCities() {
      this.loading = true;
      const endpoint = this.$store.state.endpoints.offers.public.cities;
      axios.get(endpoint, { params: { region: this.region, country: this.country } })
        .then(response => {
          this.cities = response.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title-filter{
  height: 19px;
  width: 118px;
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.text-filter{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.with-filter{
  width: 192px;
  background-color: #EE8146;
  rounded: 100px;
}
.without-filter{
  width: 192px;
  border-radius: 6px;
  background-color: #FFF0E8;
}
.text-select{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.v-select--is-menu-active{
  margin-bottom: 311px !important;
  transition: 191ms;
}
@media (min-width: 600px) {
  .v-select--is-menu-active{
    margin-bottom: unset !important;
    transition: unset !important;
  }
}
</style>
