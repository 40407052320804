<template>
  <v-select
    v-model="pickerValue"
    :disabled="position_tiers.length < 1"
    :loading="loading"
    :items="position_tiers"
    item-value="value"
    item-text="text"
    v-bind="$attrs"
    hide-details
    hide-selected
    solo
    flat
    hide-no-data
    clearable
    :@click-clear="clearFilter()"
    height="56px"
    color="#FFFFFF"
    dark
    nudge-bottom="60"
    :class="validateValueInList ? 'with-filter' : 'without-filter'"
    :background-color="validateValueInList ? '#EE8146' : '#FFF0E8'"
  >
    <template
      v-slot:selection="{ item, selected }"
    >
      <div
        v-if="item === Object(item)"
        :selected="selected"
        class="text-select text-sm-center"
        style="color: #FFF;"
      >
        <p>
          {{ shortensSelectedText(item.text) }}
        </p>
      </div>
    </template>
    <template v-slot:label>
      <p
        style="color: #EE8146"
        class="text-sm-center ml-3"
      >
        Nivel de Cargo
      </p>
    </template>
    <template v-slot:append>
      <v-icon
        color="#EE8146"
        small
      >
        far fa-chevron-down
      </v-icon>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PositionTiersPickerV2',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pickerValue: '',
      position_tiers: [],
      loading: false,
    };
  },
  computed: {
    validateValueInList() {
      const idList = this.position_tiers.map(data => data.value);
      return idList.includes(this.value);
    },
  },
  watch: {
    pickerValue() {
      if (this.pickerValue) {
        this.$emit('input', this.pickerValue);
      }
    },
    value(newVal) {
      this.pickerValue = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadPositionTiers();
    });
  },
  methods: {
    clearFilter() {
      if (this.pickerValue === undefined) {
        this.$emit('input', this.pickerValue);
      }
    },
    shortensSelectedText(value) {
      if (value.length > 10) {
        return `${value.substr(0, 10)}...`;
      }
      return value;
    },
    loadPositionTiers() {
      this.loading = true;
      axios.get(this.$store.state.endpoints.offers.public.positionTiers)
        .then(resp => {
          this.position_tiers = resp.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.title-filter{
  height: 19px;
  width: 118px;
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.text-filter{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.with-filter{
  width: 192px;
  background-color: #EE8146;
  rounded: 100px;
}
.without-filter{
  width: 192px;
  border-radius: 6px;
  background-color: #FFF0E8;
}
.text-select{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.v-select--is-menu-active{
  margin-bottom: 311px !important;
  transition: 191ms;
}
@media (min-width: 600px) {
  .v-select--is-menu-active{
    margin-bottom: unset !important;
    transition: unset !important;
  }
}
</style>
