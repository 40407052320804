<template>
  <v-select
    v-model="pickerValue"
    :disabled="filter_list.length < 1"
    :loading="loading"
    :items="filter_list"
    item-value="filter"
    item-text="text"
    v-bind="$attrs"
    single-line
    full-width
    hide-details
    hide-selected
    hide-no-data
    clearable
    height="22px"
    color="#000"
    class="order-by pb-5"
    nudge-bottom="50"
  >
    <template
      v-slot:selection="{ item, selected }"
    >
      <div
        v-if="item === Object(item)"
        :selected="selected"
        class="text-select text-sm-center mt-3 ml-3"
      >
        <p
          style="color: #000;"
          class="text-sm-center"
        >
          Ordenar por
        </p>
      </div>
    </template>
    <template v-slot:label>
      <div
        class="text-select text-sm-center ml-3"
      >
        <p
          style="color: #000"
          class="text-sm-center"
        >
          Ordenar por
        </p>
      </div>
    </template>
    <template v-slot:append>
      <v-icon
        style="position: relative"
        color="black"
        small
      >
        far fa-chevron-down
      </v-icon>
    </template>
  </v-select>
</template>

<script>

export default {
  name: 'OrderByPicker',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pickerValue: '',
      filter_list: [
        {
          text: 'Orden alfabético A-Z',
          filter: 'title',
        },
        {
          text: 'Orden alfabético Z-A',
          filter: '-title',
        },
        {
          text: 'Ordenar por fecha: Más reciente',
          filter: '-published_date',
        },
        {
          text: 'Ordenar por fecha: Más antiguo',
          filter: 'published_date',
        },
      ],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
      this.gaEvent('Order By', 'offers', 'Order By', 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
    });
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
  },
};
</script>
<style scoped>
  .order-by{
    box-sizing: border-box;
    height: 42px;
    border: 1px solid rgba(0,0,0,0.54);
    border-radius: 6px;
  }
</style>
