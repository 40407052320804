import { render, staticRenderFns } from "./Offers.vue?vue&type=template&id=496a51fd&scoped=true&"
import script from "./Offers.vue?vue&type=script&lang=js&"
export * from "./Offers.vue?vue&type=script&lang=js&"
import style0 from "./Offers.vue?vue&type=style&index=0&id=496a51fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496a51fd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VCard,VCardTitle,VChip,VContainer,VDialog,VFlex,VIcon,VLayout,VPagination})
