<template>
  <div v-if="geo">
    <Search
      :filters="filters"
      @loadOffers="loadOffers(false)"
      @openDialogFilters="filter_modal = true"
    />
    <v-container
      v-if="$vuetify.breakpoint.smAndUp"
      fluid
      grid-list-lg
      style="background-color: white"
    >
      <v-layout
        justify-center
        row
        wrap
      >
        <form
          @submit.prevent="loadOffers(false)"
        >
          <v-layout
            wrap
          >
            <v-flex
              lg2
              md3
              sm4
            >
              <AreaPickerV2
                v-model="filters.areas"
              />
            </v-flex>
            <v-flex
              lg2
              md3
              sm4
            >
              <PositionTiersPicker
                v-model="filters.position_tier"
              />
            </v-flex>
            <v-flex
              lg2
              md3
              sm4
            >
              <ContractPicker
                v-model="filters.contracts"
              />
            </v-flex>
            <v-flex
              lg2
              md3
              sm4
            >
              <RentPicker
                v-model="filters.range_rent"
              />
            </v-flex>
            <v-flex
              lg2
              md3
              sm4
            >
              <CityPickerV3
                :key="filters.region"
                v-model="filters.city"
                :region="filters.region"
                :country="geo"
                :disabled="!filters.region"
              />
            </v-flex>
            <v-flex
              align-self-center
              lg2
              md4
              sm4
            >
              <v-btn
                flat
                class="limpiar-filtro text-none"
                @click="cleanFilters()"
              >
                Limpiar filtro
              </v-btn>
            </v-flex>
          </v-layout>
        </form>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="filter_modal"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-layout justify-end>
          <v-btn
            icon
            @click="filter_modal = false"
          >
            <v-icon>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-layout>
        <v-card-title class="buscar">
          <v-icon
            small
            style="color: rgba(0,0,0,0.6);"
            class="mr-2"
          >
            far fa-sliders-h
          </v-icon>
          Filtrar
        </v-card-title>
        <v-container>
          <v-layout
            row
            wrap
            mt-4
          >
            <v-flex xs12>
              <AreaPickerV2
                v-model="filters.areas"
                class="mb-2"
                style="width: auto"
              />
            </v-flex>
            <v-flex xs12>
              <PositionTiersPicker
                v-model="filters.position_tier"
                class="mb-2"
                style="width: auto"
              />
            </v-flex>
            <v-flex xs12>
              <ContractPicker
                v-model="filters.contracts"
                class="mb-2"
                style="width: auto"
              />
            </v-flex>
            <v-flex xs12>
              <RentPicker
                v-model="filters.range_rent"
                class="mb-2"
                style="width: auto"
              />
            </v-flex>
            <v-flex xs12>
              <CityPickerV3
                :key="filters.region"
                v-model="filters.city"
                :region="filters.region"
                :country="geo"
                :disabled="!filters.region"
                class="mb-2"
                style="width: auto"
              />
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            justify-center
            mt-4
          >
            <v-btn
              style="color: #EE8146;"
              flat
              class="limpiar-filtro text-capitalize"
              @click="cleanFilters()"
            >
              Limpiar filtro
            </v-btn>
          </v-layout>
          <v-layout>
            <v-btn
              block
              large
              color="#EE8146"
              class="rectangle elevation-0 text-capitalize white--text"
              @click="loadOffers();filter_modal = false"
            >
              Aplicar filtro
            </v-btn>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
    <v-container
      fluid
      grid-list-lg
      style="background-color: #FAFAFA"
    >
      <v-layout
        v-if="geo"
        align-center
        justify-center
        row
      >
        <v-flex
          v-if="offers.length > 0"
          :md8="!userIsLoggedIn"
        >
          <p
            v-if="!(!this.$route.query.keyword
              && !this.$route.query.region && !this.$route.query.city)"
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-search' : 'title-search-responsive'"
          >
            {{ text_search }}
          </p>
          <p
            v-if="$route.query.keyword"
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-jobs' : 'title-jobs-responsive'"
          >
            Trabajos de {{ $route.query.keyword }}
          </p>
          <p
            v-else
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-jobs' : 'title-jobs-responsive'"
          >
            Empleos para ti
          </p>
        </v-flex>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-flex
            v-if="offers.length > 0"
            :md2="!userIsLoggedIn"
            :md3="userIsLoggedIn"
          >
            <OrderByPicker
              v-model="filters.order_by"
            />
          </v-flex>
        </template>
      </v-layout>
      <v-layout
        v-if="!$vuetify.breakpoint.smAndUp"
        row
        wrap
        align-center
        justify-center
      >
        <v-flex
          v-if="area_name"
          class="pa-0"
        >
          <v-chip
            small
            class="primary primary--text"
            outline
          >
            {{ area_name }}
            <v-icon
              small
              class="pl-2"
              @click="filters.areas = ''"
            >
              fas fa-times-circle
            </v-icon>
          </v-chip>
        </v-flex>
        <v-flex
          v-if="position_name"
          class="pa-0"
        >
          <v-chip
            small
            class="primary primary--text"
            outline
          >
            {{ position_name }}
            <v-icon
              small
              class="pl-2"
              @click="filters.position_tier = ''"
            >
              fas fa-times-circle
            </v-icon>
          </v-chip>
        </v-flex>
        <v-flex
          v-if="contract_name"
          class="pa-0"
        >
          <v-chip
            small
            class="primary primary--text"
            outline
          >
            {{ contract_name }}
            <v-icon
              small
              class="pl-2"
              @click="filters.contracts = ''"
            >
              fas fa-times-circle
            </v-icon>
          </v-chip>
        </v-flex>
        <v-flex
          v-if="filters.range_rent"
          class="pa-0"
        >
          <v-chip
            small
            class="primary primary--text"
            outline
          >
            {{ filters.range_rent.replace('', '$').replace(', ', ' a $') }}
            <v-icon
              small
              class="pl-2"
              @click="filters.range_rent = ''"
            >
              fas fa-times-circle
            </v-icon>
          </v-chip>
        </v-flex>
        <v-flex
          v-if="city_name"
          class="pa-0"
        >
          <v-chip
            small
            class="primary primary--text text-truncate"
            outline
          >
            {{ city_name }}
            <v-icon
              small
              class="pl-2"
              @click="filters.city = ''"
            >
              fas fa-times-circle
            </v-icon>
          </v-chip>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="geo"
        align-center
        justify-center
        row
        wrap
      >
        <v-flex
          v-if="offers.length === 0"
          xs12
        >
          <NoResults />
        </v-flex>
        <v-flex
          v-for="offer in offers"
          v-else
          :key="offer.uuid"
          :md10="!userIsLoggedIn"
        >
          <PublicOfferCardV2
            :offer="offer"
            :show-perks="true"
            :disable-favourite="true"
            @setFavourite="isFavourite => offer.is_favourite = isFavourite"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex
      v-if="offers.length > 0"
      xs12
      mb-4
    >
      <div class="text-xs-center">
        <v-pagination
          v-model="page"
          circle
          :length="pages"
          class="elevation-0"
        />
      </div>
    </v-flex>
    <v-flex
      xs12
    >
      <div style="background: #ff6625">
        <Footer
          :color="colorFooter"
        />
      </div>
    </v-flex>
  </div>
</template>

<script>
import axios from 'axios';
import AreaPickerV2 from '@/components/inputs/AreaPickerV2.vue';
import PositionTiersPicker from '@/components/inputs/PositionTiersPicker.vue';
import ContractPicker from '@/components/inputs/ContractPicker.vue';
import RentPicker from '@/components/inputs/RentPicker.vue';
import OrderByPicker from '@/components/inputs/OrderByPicker.vue';
import CityPickerV3 from '@/components/inputs/CityPickerV3.vue';
import cutString from '@/utils/text';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import RSSFeedMixin from '@/components/mixins/RSSFeedMixin.vue';
import GeoComputedMixin from '@/components/mixins/geo/GeoComputedMixin.vue';
import PublicOfferCardV2 from '@/components/offers/PublicOfferCardV2.vue';
import Search from '@/components/offers/Search.vue';
import { parseJwt, JwtIsExpired } from '@/utils/jwt';
import Footer from '@/views/Footer.vue';
import NoResults from '@/components/offers/NoResults.vue';

export default {
  name: 'Offers',
  components: {
    PublicOfferCardV2,
    AreaPickerV2,
    PositionTiersPicker,
    ContractPicker,
    RentPicker,
    OrderByPicker,
    CityPickerV3,
    Search,
    Footer,
    NoResults,
  },
  mixins: [
    JWTConfigMixin,
    RSSFeedMixin,
    GeoComputedMixin,
  ],
  data() {
    return {
      signup_modal: false,
      youtube_modal: false,
      filter_modal: false,
      username: '',
      password: '',
      banners: [],
      headers: [
        {
          text: 'Empresa',
        },
        {
          text: 'Cargo',
        },
        {
          text: 'Acciones',
        },
      ],
      offers: [],
      city_region: [],
      regions: [],
      cities: [],
      areas: [],
      position_tiers: [],
      contracts: [],
      city_name: '',
      region_name: '',
      area_name: '',
      position_name: '',
      contract_name: '',
      filters: {
        areas: '',
        country: '',
        keyword: '',
        region: '',
        city: '',
        position_tier: '',
        contracts: '',
        range_rent: '',
        order_by: '',
      },
      page: 1,
      nextPage: '',
      prevPage: '',
      pages: 1,
      count_offers: 0,
      colorFooter: '#FAFAFA',
    };
  },
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
    filters_payload() {
      const newObj = { ...this.filters };
      Object.keys(this.filters).forEach(prop => {
        if (!this.filters[prop]) {
          delete newObj[prop];
        }
      });
      return newObj;
    },
    outplacement_link() {
      if (this.geo === 'PE') {
        return 'https://mandomedio.com/peru/workshop/';
      }
      if (this.geo === 'CO') {
        return 'https://mandomedio.com/colombia/transicion-de-carrera/asesoria-laboral/';
      }
      // default to chilean (CL) link
      return 'https://mandomedio.com/outplacement-personas/';
    },
    valid_filters() {
      return [this.filters].map(data => ({
        areas: data.areas,
        position_tier: data.position_tier,
        contracts: data.contracts,
        range_rent: data.range_rent,
        order_by: data.order_by,
        city: data.city,
      }));
    },
    filters_query_params() {
      const newObjFilter = { ...this.filters };
      Object.keys(this.filters).forEach(value => {
        if (!this.filters[value]) {
          delete newObjFilter[value];
        }
        if (value === 'country') {
          delete newObjFilter[value];
        }
      });
      return newObjFilter;
    },
    text_search() {
      if (this.$route.query.keyword && !this.$route.query.region && !this.$route.query.city) {
        return `${this.count_offers} empleos de ${this.$route.query.keyword}`;
      }
      if (!this.$route.query.keyword && this.$route.query.region && !this.$route.query.city) {
        return `${this.count_offers} empleos en ${this.region_name}`;
      }
      if (!this.$route.query.keyword && !this.$route.query.region && this.$route.query.city) {
        return `${this.count_offers} empleos en ${this.city_name}`;
      }
      if (this.$route.query.keyword && this.$route.query.region && !this.$route.query.city) {
        return `${this.count_offers} empleos de ${this.$route.query.keyword} en ${this.region_name}`;
      }
      if (this.$route.query.keyword && !this.$route.query.region && this.$route.query.city) {
        return `${this.count_offers} empleos de ${this.$route.query.keyword} en ${this.city_name}`;
      }
      if (!this.$route.query.keyword && this.$route.query.region && this.$route.query.city) {
        return `${this.count_offers} empleos en ${this.city_name}, ${this.region_name}`;
      }
      if (this.$route.query.keyword && this.$route.query.region && this.$route.query.city) {
        return `${this.count_offers} empleos de ${this.$route.query.keyword} en ${this.city_name}, ${this.region_name}`;
      }
      return '';
    },
  },
  watch: {
    valid_filters() {
      this.loadOffers(false);
    },
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        pageEndpoint = `${this.$store.state.endpoints.offers.public.list}?page=${newVal}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      this.verifyParams();
      this.firstLoad();
    });
  },
  methods: {
    loadRegionName() {
      const endpoint = this.$store.state.endpoints.offers.public.regions;
      axios.get(endpoint, { params: { country: this.country } })
        .then(response => {
          if (this.$route.query.region) {
            this.regions = response.data;
            const region = this.regions.find(payload => payload.uuid === this.$route.query.region);
            this.region_name = region.name;
          }
        });
    },
    loadCityName() {
      const endpoint = this.$store.state.endpoints.offers.public.cities;
      axios.get(endpoint, { params: { country: this.country } })
        .then(response => {
          if (this.$route.query.city) {
            this.cities = response.data;
            const city = this.cities.find(payload => payload.uuid === this.$route.query.city);
            this.city_name = city.name;
          }
        });
    },
    loadAreaName() {
      const endpoint = this.$store.state.endpoints.offers.public.superAreas;
      axios.get(endpoint)
        .then(response => {
          if (this.$route.query.areas) {
            this.areas = response.data;
            const area = this.areas.find(payload => payload.uuid === this.$route.query.areas);
            this.area_name = area.name;
          }
        });
    },
    loadPositionTiersName() {
      const endpoint = this.$store.state.endpoints.offers.public.positionTiers;
      axios.get(endpoint)
        .then(response => {
          if (this.$route.query.position_tier) {
            this.position_tiers = response.data;
            const position = this.position_tiers.find(
              payload => payload.value === this.$route.query.position_tier,
            );
            this.position_name = position.text;
          }
        });
    },
    loadContractName() {
      const endpoint = this.$store.state.endpoints.offers.public.contracts;
      axios.get(endpoint)
        .then(response => {
          if (this.$route.query.contracts) {
            this.contracts = response.data;
            const con = this.contracts.find(
              payload => payload.value === this.$route.query.contracts,
            );
            this.contract_name = con.text;
          }
        });
    },
    parseContent(rssContent) {
      return rssContent.split('<p>')[1].split('</p>')[0];
    },
    cleanFilters() {
      this.filters.range_rent = '';
      this.filters.contracts = '';
      this.filters.areas = '';
      this.filters.position_tier = '';
      this.filters.city = '';
    },
    verifyParams() {
      if (this.$route.query.keyword) {
        this.filters.keyword = this.$route.query.keyword;
      }
      if (this.$route.query.areas) {
        this.filters.areas = this.$route.query.areas;
      }
      if (this.$route.query.city) {
        this.filters.city = this.$route.query.city;
      }
      if (this.$route.query.contracts) {
        this.filters.contracts = (parseInt(this.$route.query.contracts, 10));
      }
      if (this.$route.query.position_tier) {
        this.filters.position_tier = parseInt(this.$route.query.position_tier, 10);
      }
      if (this.$route.query.range_rent) {
        this.filters.range_rent = this.$route.query.range_rent;
      }
      if (this.$route.query.region) {
        this.filters.region = this.$route.query.region;
      }
      if (this.$route.query.order_by) {
        this.filters.order_by = this.$route.query.order_by;
      }
    },
    firstLoad() {
      this.loadOffers(true);
      this.loadRSSFeed(this.geo);
    },
    loadOffers(firstLoad) {
      if (firstLoad) {
        this.filters.country = this.geo;
      }
      if (this.filters.region) {
        this.loadRegionName();
      } else {
        this.region_name = '';
      }
      if (this.filters.city) {
        this.loadCityName();
      } else {
        this.city_name = '';
      }
      if (this.filters.areas) {
        this.loadAreaName();
      } else {
        this.area_name = '';
      }
      if (this.filters.position_tier) {
        this.loadPositionTiersName();
      } else {
        this.position_name = '';
      }
      if (this.filters.contracts) {
        this.loadContractName();
      } else {
        this.contract_name = '';
      }
      this.$router.push({
        name: 'candidate:offers',
        query: this.filters_query_params,
      });
      this.$store.commit('setLoading');
      const url = `${this.$store.state.endpoints.offers.public.list}`;
      axios.get(url, { ...this.jwtConfig, params: { ...this.filters_payload } }).then(response => {
        this.nextPage = response.data.next;
        this.prevPage = response.data.previous;
        this.offers = response.data.results;
        this.pages = Math.ceil(response.data.count / response.data.results.length);
        this.count_offers = response.data.count;
        this.$store.commit('unsetLoading');
        this.page = 1;
      });
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, { params: { ...this.filters_payload } })
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.offers = response.data.results;
          this.$store.commit('unsetLoading');
          window.scrollTo(0, 0);
        });
    },
    cutString(text) {
      return cutString(text, 250);
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.username = payload.username;
      this.password = payload.password;
      this.authenticate();
    },
    authenticate() {
      this.$store.commit('setLoading');

      const payload = {
        username: this.username,
        password: this.password,
      };

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
  .link_to_offer:hover {
    cursor: pointer;
  }
  .link_to_offer {
    text-decoration: none;
  }
  .title-jobs {
    height: 32px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 32px;
  }
  .title-jobs-responsive {
    height: 32px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 32px;
  }
  .button-jobs{
    height: 36px;
    width: 142px;
    border-radius: 6px;
    background-color: rgba(0,0,0,0);
  }
  .title-search {
   height: 5px;
   color: #EE8146;
   font-family: Lato;
   font-size: 13px;
   letter-spacing: 0.37px;
   line-height: 22px;
   }
  .title-search-responsive {
    height: 28px;
    color: #EE8146;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.13px;
    line-height: 24px;
  }
  .limpiar-filtro {
    height: 24px;
    color: rgba(0,0,0,0.6);
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    text-align: center;
  }
  .v-menu__content {
    max-height: 300px !important;
    min-width: 343px !important;
    bottom: 115px !important;
    left: 16px !important;
    transform-origin: left top !important;
    z-index: 206 !important;
  }
</style>
