<template>
  <v-select
    v-model="pickerValue"
    :disabled="range_rent.length < 1"
    :loading="loading"
    :items="range_rent"
    item-value="range"
    item-text="text"
    v-bind="$attrs"
    hide-details
    hide-selected
    solo
    flat
    hide-no-data
    clearable
    :@click-clear="clearFilter()"
    height="56px"
    color="#FFFFFF"
    dark
    nudge-bottom="60"
    :class="validateValueInList ? 'with-filter' : 'without-filter'"
    :background-color="validateValueInList ? '#EE8146' : '#FFF0E8'"
  >
    <template
      v-slot:selection="{ item, selected }"
    >
      <div
        v-if="item === Object(item)"
        :selected="selected"
        class="text-select text-sm-center"
        style="color: #FFF;"
      >
        <p>
          {{ shortensSelectedText(item.text) }}
        </p>
      </div>
    </template>
    <template v-slot:label>
      <p
        style="color: #EE8146"
        class="text-sm-center ml-3"
      >
        Rango de renta
      </p>
    </template>
    <template v-slot:append>
      <v-icon
        color="#EE8146"
        small
      >
        far fa-chevron-down
      </v-icon>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RentPickerV2',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pickerValue: '',
      range_rent: [
        {
          text: '$0 a $500.000',
          range: '0, 500000',
        },
        {
          text: '$500.00 a $750.000',
          range: '500000, 750000',
        },
        {
          text: '$750.00 a $1.000.000',
          range: '750000, 1000000',
        },
        {
          text: '$1.000.000 a $1.500.000',
          range: '1000000, 1500000',
        },
        {
          text: '$1.500.000 a $2.000.000',
          range: '1500000, 2000000',
        },
        {
          text: '+ de $2.000.000',
          range: '2000000, 999999999',
        },
      ],
      loading: false,
    };
  },
  computed: {
    validateValueInList() {
      const idList = this.range_rent.map(data => data.range);
      return idList.includes(this.value);
    },
  },
  watch: {
    pickerValue() {
      if (this.pickerValue) {
        this.$emit('input', this.pickerValue);
      }
    },
    value(newVal) {
      this.pickerValue = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
    });
  },
  methods: {
    clearFilter() {
      if (this.pickerValue === undefined) {
        this.$emit('input', this.pickerValue);
      }
    },
    shortensSelectedText(value) {
      if (value.length > 10) {
        return `${value.substr(0, 10)}...`;
      }
      return value;
    },
    loadContracts() {
      this.loading = true;
      axios.get(this.$store.state.endpoints.offers.public.contracts)
        .then(resp => {
          this.range_rent = resp.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.title-filter{
  height: 19px;
  width: 118px;
  color: #EE8146;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.text-filter{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.with-filter{
  width: 192px;
  background-color: #EE8146;
  rounded: 100px;
}
.without-filter{
  width: 192px;
  border-radius: 6px;
  background-color: #FFF0E8;
}
.text-select{
  height: 19px;
  width: 98px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
}
.v-select--is-menu-active{
  margin-bottom: 311px !important;
  transition: 191ms;
}
@media (min-width: 600px) {
  .v-select--is-menu-active{
    margin-bottom: unset !important;
    transition: unset !important;
  }
}
.v-menu__content .theme--light .v-menu__content--fixed .menuable__content__active{
  -webkit-box-shadow: unset !important;
  box-shadow:unset  !important;
}
</style>
